import React from 'react'
import { css } from 'styled-components'
import { minMq } from '@/shared/styles/breakPoints'
import variables from '@/shared/styles/variables'

const sectionTitle = css`
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 30px;
  padding-bottom: 10px;
  position: relative;

  ${minMq('MD')} {
    font-size: 2.2rem;
    padding-bottom: 15px;
  }

  &::after {
    background-color: ${variables.SUB_C};
    bottom: 0;
    content: '';
    height: 0.2rem;
    left: 0;
    position: absolute;
    width: 50px;
  }
`

interface OwnProps {
  sectionTitle: string
}

type Props = OwnProps

const SectionTitle: React.FC<Props> = (props) => {
  return (
    <header>
      <h2 css={sectionTitle}>{props.sectionTitle}</h2>
    </header>
  )
}

export default SectionTitle

import React, { AnchorHTMLAttributes } from 'react'
import { css } from 'styled-components'
import { minMq } from '@/shared/styles/breakPoints'
import variables from '@/shared/styles/variables'
import Link from 'next/link'

const myBtn = css`
  background-color: ${variables.THEME_C};
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
  color: ${variables.WHITE};
  display: flex;
  justify-content: center;
  padding: 10px;
`

type OwnProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  href: string
  text: string
}

const MyBtn: React.FC<OwnProps> = ({ href, text, ...props }) => {
  return (
    <a href={href} {...props} css={myBtn}>
      <span>{text}</span>
    </a>
  )
}

export default MyBtn

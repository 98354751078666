import { css } from 'styled-components'
import { maxMq, minMq } from '@/shared/styles/breakPoints'
import variables from '@/shared/styles/variables'

const siteFooter = css`
  background-color: ${variables.LIGHT_GRAY};
  margin-top: 60px;
  padding-bottom: 26px;
  padding-top: 30px;
  width: 100%;

  ${minMq('MD')} {
    margin-top: 80px;
  }
`

const content = css`
  ${minMq('MD')} {
    align-items: flex-end;
    display: flex;
    gap: 20px;
    justify-content: space-between;
  }
`

const wrapFooter = css`
  ${maxMq('MD')} {
    margin-bottom: 15px;
  }
`

const title = css`
  margin-bottom: 10px;
`

const text = css`
  font-size: 1.5rem;

  > a {
    color: ${variables.LINK_TXC};
    text-decoration: underline;

  }
`

const copyright = css`
  font-size: 1.5rem;
  padding-bottom: 10px;
  padding-top: 10px;

  ${maxMq('MD')} {
    text-align: center;
  }
`


export const style = {
  siteFooter,
  content,
  wrapFooter,
  title,
  text,
  copyright
}

import { css } from 'styled-components'
import { minMq } from '@/shared/styles/breakPoints'
import variables from '@/shared/styles/variables'

export const page = css`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: flex;
  flex-flow: column;
  font-family: "Noto Sans JP", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", sans-serif;
  font-size: 1.6rem;
  justify-content: flex-start;
  min-height: 100vh;
  position: relative;

  ${minMq('MD')} {
    font-size: 1.8rem;
  }
`

export const style = {
  page
}
export default {
  WHITE: '#fff',
  GRAY: '#b6b6b6',
  LIGHT_GRAY: '#f5f5f5',
  BLACK: '#000',
  RED: '#e60012',
  GREEN: '#488b4b',
  BLUE: '#0f67c3',
  PINK: '#e3556a',
  BASE_BGC: '#fef6e5',
  SUB_BGC: '#f2f2f2',
  BASE_BDC: '#4d311c',
  SUB_BDC: '#c4c4c4',
  BASE_TXC: '#333',

  // テーマカラー
  THEME_C: '#34b9be',
  SUB_C: '#e50012',

  // エラー表示用の強調色
  ERR_C: '#f20606',
  //$err_bgc: tint($err_c, 95%);

  // リンクの色
  LINK_TXC: '#1a73e8',
  LINK_HVC: '#18e2e2',

  // ボタンの色
  BTN_BGC: '#fef6e5',
  //$btn_hvc: lighten($theme_c, 15%);
  BTN_TXC: '#4d311c',

  // カレンダーUIの色
  CAL_BGC: '#fff',
  CAL_TXC: '#333',
  CAL_BDC: '#aeaeae',
  CAL_HVC: '#ededed',
  CAL_SAT_TXC: '#0f67c3',
  CAL_SUN_TXC: '#e60012',

  // size
  UNIT: '5px',
  BLOCK_SPACE: `${5 * 6}px`,
  BLOCK_PAD: `${5 * 4}px`,

  CONTENT_W: '980px',
  HEADER_H_PC: '70px',
  HEADER_H_SP: '60px',
  GLOBAL_NAV_W: '44px',
  LOGO_H_SP: '30px',
  BTN_CONTACT_H_SP: '40px'
}

import { css } from 'styled-components'
import { minMq } from '@/shared/styles/breakPoints'


const header = css`
  margin-bottom: 30px;

  ${minMq('MD')} {
    margin-bottom: 50px;
  }
`

const titleImg = css`
  display: block;
  margin:  auto;
  width: min(90vw, 810px);
`

export const style = {
  header,
  titleImg
}
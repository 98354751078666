import React from 'react'
import { style } from './SiteFooter.style'
import Content from '@/components/Content/Content'

const SiteFooter: React.FC = () => {
  return (
    <footer css={style.siteFooter}>
      <Content css={style.content}>
        <div css={style.wrapFooter}>
          <p css={style.title}>サイトに関する問い合わせ</p>
          <p css={style.text}>新潟大学医歯学総合病院&emsp;感染管理部&emsp;須貝</p>
          <p css={style.text}>
            E-mail：<a href="mailto:megumi@med.niigata-u.ac.jp">megumi@med.niigata-u.ac.jp</a>
          </p>
        </div>
        <div css={style.copyright}>
          <p>Copyright© 新潟大学病院 感染管理部</p>
        </div>
      </Content>
    </footer>
  )
}

export default SiteFooter
